@font-face {
  font-family: 'Prompt-Regular';
  src: url('resources/fonts/Prompt-Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('resources/fonts/Prompt-Bold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('resources/fonts/Prompt-Medium.ttf');
}

body {
  font-weight: 400;
  font-family: 'Prompt-Regular';
  /* background-color: #383838; */
}

.cnindex {
  margin-bottom: 10px;
}

.cnindex .header {
  overflow: hidden;
  /* padding: 10px 0px; */
}

.cnindex .cn-content {
  /* margin-top: 5px; */
  background-image: url('resources/images/content-image.jpg');
  background-size: 100% 100%;
  min-height: 300px;
  padding: 0 10px 10px;
}

.cnindex .cn-content .content-detail {
  margin-top: 15px;
  background-color: #fff;
}

.cnindex .cn-content .content-detail .col-left {
  border-right: 2px solid #2a3a5c;
}

.cnindex .cn-content .content-detail .col-left .index-detail .title.down {
  color: #22ac38;
}

.cnindex .cn-content .content-detail .col-left .index-detail .title {
  font-size: 36px;
  text-align: center;
}

.cnindex .cn-content .content-detail .col-left .index-detail ul {
  padding-left: 0;
}

.cnindex .cn-content .content-detail .col-left .index-detail ul li {
  list-style: none;
  border-top: 1px solid #eee;
  height: 30px;
  line-height: 29px;
}

.cnindex .cn-content .content-detail .col-left .index-detail ul li .name {
  display: inline-block;
  width: 60%;
  padding-left: 10px;
  border-right: 1px solid #eee;
  background-color: #f6f6f6;
  color: #7f7f7f;
}

.cnindex .cn-content .content-detail .col-left .index-detail ul li .value {
  display: inline-block;
  width: 38%;
  padding-right: 10px;
  vertical-align: middle;
  text-align: right;
}

.cnindex .cn-content .content-detail .col-left .index-detail ul li .change {
  color: #db3338;
}

.cnindex .cn-content .content-detail .col-left .index-detail ul li .value .value-cont.up {
  color: #22ac38;
}

.cnindex .cn-content .content-detail .col-left .index-detail ul li .value .value-cont.down {
  color: #db3338;
}

.history {
  margin-top: 15px;
  background-color: #fff;
  padding: 10px;
}

.history h4,
.history {
  color: #162e64;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.history .table tr td,
.history .table tr th {
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #162e64;
  color: #162e64;
}